import axios from 'axios';

async function RefreshTokenRequest(refreshToken) {
  // eslint-disable-next-line no-return-await
  return await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/tokens?grantType=refreshToken`,
    {
      refreshToken,
    },
  );
}

export default RefreshTokenRequest;
