/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import RefreshTokenRequest from '../../api/tokens/RefreshTokenRequest';
import LoadingIndicatorPageWithDelay from '../../pages/LoadingIndicatorPageWithDelay';

const moment = require('moment');

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const cookies = new Cookies();
  const { t } = useTranslation();

  const authorization = cookies.get('Authorization');
  const refreshToken = cookies.get('RefreshToken');
  const rememberMe = cookies.get('RememberMe');

  const [authStatus, setAuthStatus] = useState(false);
  const [acctStatus, setAcctStatus] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const refreshTokenProcess = async () => {
    try {
      const res = await RefreshTokenRequest(refreshToken);
      if (rememberMe) {
        cookies.set('Authorization', res.data.data.accessToken, {
          path: '/',
          maxAge: 2592000,
        });
        cookies.set('RefreshToken', res.data.data.refreshToken, {
          path: '/',
          maxAge: 2592000,
        });
        cookies.set('RememberMe', true, { path: '/', maxAge: 2592000 });
      } else {
        cookies.set('Authorization', res.data.data.accessToken, { path: '/' });
        cookies.set('RefreshToken', res.data.data.refreshToken, { path: '/' });
      }
      const decoded = jwt_decode(res.data.data.accessToken);
      setAcctStatus(decoded.acctStatus);
      setAuthStatus(true);
    } catch (err) {
      setAuthStatus(false);
    }
  };

  const auth = async () => {
    let removeCookie = false;

    if (authorization) {
      // if authorization cookie exists
      const decoded = jwt_decode(authorization);
      setAcctStatus(decoded.acctStatus);

      try {
        // request new token if about to expire
        if (moment.unix(decoded.exp) - moment().add(60, 'seconds') <= 0) {
          await refreshTokenProcess();
        }
        setAuthStatus(true);
      } catch (error) {
        setAuthStatus(false);
        removeCookie = true;
      }
    } else if (refreshToken) {
      await refreshTokenProcess();
    } else {
      setAuthStatus(false);
      removeCookie = true;
    }

    if (removeCookie) {
      cookies.remove('RememberMe', { path: '/' });
      cookies.remove('Authorization', { path: '/' });
      cookies.remove('RefreshToken', { path: '/' });
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    if (!isLoaded) {
      auth();
    }
  });

  return (
    <Route
      {...rest}
      // eslint-disable-next-line consistent-return
      render={(props) => {
        if (!isLoaded) {
          return <LoadingIndicatorPageWithDelay />;
        }
        if (!authStatus) {
          return (
            <Redirect
              to={{
                pathname: '/signin',
                search: `?callback=${location.pathname}${location.search}`,
                state: {
                  warningMessage: t(
                    'web.components.middlewares.private_route.signout_message',
                  ),
                },
              }}
            />
          );
        }
        if (acctStatus === 0) {
          if (location.pathname.startsWith('/pending-email-verification')) {
            return <Component {...props} />;
          }
        } else if (acctStatus === 1) {
          if (location.pathname.startsWith('/get-started') || location.pathname.startsWith('/signout')) {
            return <Component {...props} />;
          }
          return <Redirect to="/get-started/import-bookmarks" />;
        } else if (acctStatus === 2) {
          if (location.pathname.startsWith('/get-started')) {
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/signin',
                search: `?callback=${location.pathname}${location.search}`,
                state: {
                  warningMessage: t(
                    'web.components.middlewares.private_route.signout_message',
                  ),
                },
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
