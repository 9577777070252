import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomBarLoader from '../components/loaders/CustomBarLoader';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const { t } = useTranslation();

  const delay = 500;
  const [showLoadingIndicator, setLoadingIndicatorVisibility] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);

    // this will clear Timeout when component unmont like in willComponentUnmount
    return () => {
      clearTimeout(timer);
    };
  });

  return showLoadingIndicator ? (
    <div className={classes.root}>
      <CustomBarLoader />
      <br />
      <p style={{ textAlign: 'center' }}>
        {t('web.pages.loading_indicator_page_with_delay.loading_message')}
      </p>
    </div>
  ) : null;
}
