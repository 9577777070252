import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

export default function CustomBarLoader() {
  return (
    <div>
      <BarLoader
        width={150}
        height={4}
        css={{ display: 'inherit' }}
        color="#005ae6"
        loading
      />
    </div>
  );
}
