import { Switch, Route } from 'react-router-dom';
import React, {
  lazy, useState, useEffect,
} from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import PrivateRoute from './components/middlewares/PrivateRoute';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const SignIn = lazy(() => import('./pages/preAuth/SignIn'));
const SignUp = lazy(() => import('./pages/preAuth/SignUp'));
const ResetPassword = lazy(() => import('./pages/preAuth/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/preAuth/ForgotPassword'));
const PasswordResetEmailSent = lazy(() => import('./pages/preAuth/PasswordResetEmailSent'));
const PendingEmailVerification = lazy(() => import('./pages/preAuth/PendingEmailVerification'));
const EmailVerification = lazy(() => import('./pages/preAuth/EmailVerification'));

const ImportBookmarks = lazy(() => import('./pages/getStarted/ImportBookmarks'));
const AddBookmarklet = lazy(() => import('./pages/getStarted/AddBookmarklet'));

const Station = lazy(() => import('./pages/stations/Station'));
const Inbox = lazy(() => import('./pages/stations/Inbox'));
const Recent = lazy(() => import('./pages/stations/Recent'));
const Search = lazy(() => import('./pages/stations/Search'));
const Share = lazy(() => import('./pages/stations/Share'));
const RecycleBin = lazy(() => import('./pages/stations/RecycleBin'));
const AddBookmarkletUtil = lazy(() => import('./pages/stations/utilities/AddBookmarklet'));
const ImportBookmarkUtil = lazy(() => import('./pages/stations/utilities/ImportBookmark'));

const Bookmarklet = lazy(() => import('./pages/bookmarklet/Bookmarklet'));

const PublicPage = lazy(() => import('./pages/public/index'));

const SignOut = lazy(() => import('./pages/misc/SignOut'));
const CommunicationPreferences = lazy(() => import('./pages/misc/CommunicationPreferences'));

function App() {
  const { enqueueSnackbar } = useSnackbar();

  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  const onUpdateClick = () => {
    // eslint-disable-next-line no-unused-expressions
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setNewVersionAvailable(false);
    window.location.reload();
  };

  const action = () => (
    <Button onClick={onUpdateClick} color="inherit" size="medium">
      Update
    </Button>
  );

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration && registration.waiting);
        setNewVersionAvailable(true);
      },
    });

    if (newVersionAvailable) {
      enqueueSnackbar('There is a new version available', {
        variant: 'default',
        persist: true,
        action,
      });
    }
  });

  return (
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route
        path="/password-reset-email-sent"
        component={PasswordResetEmailSent}
      />
      <Route path="/email-verification" component={EmailVerification} />
      <Route
        path="/pending-email-verification"
        component={PendingEmailVerification}
      />

      <PrivateRoute
        path="/get-started/import-bookmarks"
        component={ImportBookmarks}
      />
      <PrivateRoute
        path="/get-started/add-bookmarklet"
        component={AddBookmarklet}
      />

      <PrivateRoute path="/get-started" component={ImportBookmarks} />

      <Route
        path="/communication-preferences"
        component={CommunicationPreferences}
      />
      <PrivateRoute path="/signout" component={SignOut} />

      <PrivateRoute path="/folders/:folderId" component={Station} key={Date.now()} />

      <PrivateRoute path="/stations/:stationId/folders/:folderId" component={Station} key={Date.now()} />
      <PrivateRoute path="/stations/:stationId/inbox" component={Inbox} />
      <PrivateRoute path="/stations/:stationId/search" component={Search} />
      <PrivateRoute path="/stations/:stationId/recent" component={Recent} />
      <PrivateRoute path="/stations/:stationId/recycle-bin" component={RecycleBin} />
      <PrivateRoute path="/stations/:stationId" component={Station} key={Date.now()} />

      <Route path="/public/:folderId" component={PublicPage} />

      <PrivateRoute path="/bookmarklet" component={Bookmarklet} />

      <PrivateRoute
        path="/utilities/add-bookmarklet"
        component={AddBookmarkletUtil}
      />
      <PrivateRoute
        path="/utilities/import-bookmark"
        component={ImportBookmarkUtil}
      />

      <PrivateRoute path="/recent" component={Recent} />
      <PrivateRoute path="/recycle-bin" component={RecycleBin} />
      <PrivateRoute path="/inbox" component={Inbox} />
      <PrivateRoute path="/search" component={Search} />
      <PrivateRoute path="/share/:folderId" component={Share} />
      <PrivateRoute path="/share" component={Share} />
      <PrivateRoute path="/" component={Station} key={Date.now()} />
    </Switch>
  );
}

export default App;
