import { createTheme } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StationianLightTheme = createTheme({
  palette: {
    primary: {
      main: '#005ae6',
      light: '#277cf2',
    },
    secondary: {
      main: '#e91e63',
    },
    appBar: {
      main: '#ffffff',
    },
    buttonSec: {
      main: '#333333',
    },
    buttonSecDisabled: {
      main: '#757575',
    },
    white: {
      main: '#ffffff',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ebebeb',
            '&:hover': {
              backgroundColor: '#e1e1e1',
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '4px',
          border: '1px solid #e0e0e0',
          boxShadow: '4px 4px 2px -2px #d9d9d9',
          marginTop: '0px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ebebeb',
            '&:hover': {
              backgroundColor: '#e1e1e1',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '2px',
          paddingBottom: '2px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '30%',
          borderTopRightRadius: '30%',
          borderBottomLeftRadius: '30%',
          borderBottomRightRadius: '30%',
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
          },
        },
      },
    },
  },
});
